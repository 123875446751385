var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "slide-down-transition" },
      on: {
        "before-enter": _vm.beforeEnter,
        enter: _vm.enter,
        leave: _vm.leave,
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }