











import Vue from "vue"
export default Vue.extend({
  name: "SlideDownTransition",
  props: {
    maxHeight: {
      type: String,
      default: "100%"
    }
  },
  methods: {
    beforeEnter(el) {
      el.style.maxHeight = "0px"
    },
    enter(el) {
      el.style.maxHeight = this.maxHeight
    },
    leave(el) {
      el.style.maxHeight = "0px"
    }
  }
})
